.login-container {
    width: 600px;
    margin: 0 auto;
    padding: 20px;
    background-color: #f5f5f5;
    border-radius: 10px;
    height: 100vh;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 2em;
    text-align: center;
}

.login-btn {
    width: 100%;
    height: 40px;
    padding: 10px;
    text-align: center;
    border-radius: 10px;
    font-weight: bold;
    cursor: pointer;
    margin-top: 20px;
    background-color: #91c6f8;
    transition: background-color 0.3s ease;
}

.login-btn:hover {
    background-color: #64b1f8;
}

.login {
    background-color: #91c6f8;
}

.login:hover {
    background-color: #64b1f8;
}

*, *:before, *:after {
    box-sizing: border-box;
}

.g-sign-in-button {
    margin: 10px;
    display: inline-block;
    width: 240px;
    height: 50px;
    background-color: #4285f4;
    color: #fff;
    border-radius: 1px;
    box-shadow: 0 2px 4px 0 rgba(0, 0, 0, 0.25);
    transition: background-color 0.218s, border-color 0.218s, box-shadow 0.218s;
}

.g-sign-in-button:hover {
    cursor: pointer;
    -webkit-box-shadow: 0 0 3px 3px rgba(66, 133, 244, 0.3);
    box-shadow: 0 0 3px 3px rgba(66, 133, 244, 0.3);
}

.g-sign-in-button:active {
    background-color: #3367D6;
    transition: background-color 0.2s;
}

.g-sign-in-button .content-wrapper {
    height: 100%;
    width: 100%;
    border: 1px solid transparent;
}

.g-sign-in-button img {
    width: 18px;
    height: 18px;
}

.g-sign-in-button .logo-wrapper {
    padding: 15px;
    background: #fff;
    width: 48px;
    height: 100%;
    border-radius: 1px;
    display: inline-block;
}

.g-sign-in-button .text-container {
    font-family: Roboto, arial, sans-serif;
    font-weight: 500;
    letter-spacing: 0.21px;
    font-size: 16px;
    line-height: 48px;
    vertical-align: top;
    border: none;
    display: inline-block;
    text-align: center;
    width: 180px;
}

.descriptionTool {
    color: #66cc00;
    font-weight: bold;
  }