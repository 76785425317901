.ant-timeline-item-head {
    margin-top: 1em !important;
    /*
    margin-inline-start: 14%;
    */
}

.ant-timeline-item-label {
    margin-top: 1em !important;
    /*width: auto !important;*/
}

.ant-timeline-item-tail {
    inset-block-start: 17px !important;
}

.custom-radio-table .ant-radio-inner {
    border: 1px solid gray; 
}

@media (max-width: 768px) {
    .ant-collapse>.ant-collapse-item >.ant-collapse-header {
        padding: 12px 5px;
    }
}



