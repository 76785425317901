.header-container {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
}

.header-menu-selector {
    display: flex;
    flex-direction: row;
    align-items: center;
    gap: 1em;
}

.header-menu-selector:hover {
     cursor: pointer;
 }

.header-right-container {
    display: flex;
    flex-direction: row;
    justify-content: end;
    align-items: center;
    gap: 1em;
}
