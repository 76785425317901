@media (max-width: 500px) {
    .ant-list-item-meta {
        min-width: 110px !important;
    }
}

/* @media screen and (max-width: 412px) {
    .ant-list-item-meta {
        min-width: 110px !important;
    }
} */
