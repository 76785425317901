.ant-form {
    align-items: initial !important;
}

.ant-form-item-label {
    min-width: 8em !important;
}

.form_email {
    display: flex;
    flex-direction: row;
}


.TagEmailBtn {
    display: flex;
    flex-direction: row;
    height: 23px;
    width: min-content;
    margin-left: 2px;
    cursor: pointer;
}

.TagEmailInput {
    display: flex;
    flex-direction: row;
    height: 28px;
}