
@media (max-width: 767px) {
  .custom-sidebar{
    position: fixed !important;
    z-index: 99;
    height: 100%;
  }

  .ant-layout-sider-collapsed > .ant-layout-sider-trigger{
    border-radius: 10px;
    left: 10px;
    bottom: 10px !important;
  }
  
  .ant-layout-sider-collapsed {
    max-width: 0px !important;
    min-width: 0px !important;
    width: 0px !important;
  }
}
