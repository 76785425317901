.App {
  text-align: center;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

@media (prefers-reduced-motion: no-preference) {
  .App-logo {
    animation: App-logo-float infinite 3s ease-in-out;
  }
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
}

.App-link {
  color: rgb(112, 76, 182);
}

@keyframes App-logo-float {
  0% {
    transform: translateY(0);
  }
  50% {
    transform: translateY(10px);
  }
  100% {
    transform: translateY(0px);
  }
}

.ant-collapse-content-box {
  padding: 0 !important;
}

.ant-list-item-meta {
  align-items: center !important;
}

.ant-list-item-meta-title {
  margin: 0;
}

.ant-descriptions-item-container {
  align-items: baseline !important;
}

.ant-picker-calendar-mode-switch {
  display: none;
}

a {
  color: #2ab20b !important;
}

a:hover {
  color: #0e9b12 !important;
}
