.upload-list-inline .ant-upload-list-item {
    float: left;
    width: 200px;
    margin-inline-end: 8px;
}

.ant-upload-rtl.upload-list-inline .ant-upload-list-item {
    float: right;
}

.ant-upload-list-item-container {
    width: 250px;
}

.ant-upload-list-item-progress {
    width: calc(100% - 45px) !important;
}

.ant-upload-list {
    display: flex;
    gap: 2em;
    flex-flow: wrap;
    justify-content: space-evenly;
}

.delete-outlined {
    font-size: 1.2em !important;

}

.delete-outlined:hover {
    color: #f54242 !important;
}

.move-outlined {
    font-size: 1.2em !important;
}

.move-outlined:hover {
    color: #026dc4 !important;
}

.folder-card {
    border-radius: 4px;
    box-shadow: 0 2px 8px rgba(0, 0, 0, 0.15);
    transition: box-shadow 0.3s ease-in-out;
}

.folder-card:hover {
    box-shadow: 0 4px 16px rgba(0, 0, 0, 0.25);
    cursor: pointer;
}

.spin-container {
    width: 100%;
    height: 58vh;
    display: flex;
    align-items: center;
    justify-content: center;
}

.breadcrumb-item .go {
    cursor: pointer;
    padding: 4px 10px;
    border-radius: 10px;
    border: 1px solid #e9e9e9;
    transition: all 0.2s ease-in-out;
}

.breadcrumb-item .go:hover {
    border: 1px solid #2ab20b;
    color: #2ab20b;
    transition: all 0.3s ease-in-out;
}

.demo-loadmore-list {
    cursor: pointer;
}

.demo-loadmore-list:hover {
    background: #f0f0f0;
}

@media (max-width: 576px) {
    .content-bread-create-btn {
        justify-content: normal !important;
        display: grid !important;
    }
}