@media (max-width: 576px) {
    .text-show {
        display: none !important;
    }
}

@media (max-width: 768px) {
    .header-search-button {
        display: grid !important;
    }

    .ant-list .ant-list-item .ant-list-item-meta{
        max-width: none !important;
        min-width: 90px;
    }

    /* .ant-list .ant-list-item .ant-list-item-meta .ant-list-item-meta-content{
        min-width: 90px !important;
        max-width: none !important;
    } */
}

@media (min-width: 576px) {
    .eye-show {
        display: none !important;
    }
}



