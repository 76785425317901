.error-message {
    display: none;
}

.spinner {
    display: flex;
    justify-content: center;
    margin-top: 128px;
}

@media (max-width: 768px) {
    .content-table-entity{
        margin: 0 !important;
        padding: 0 !important;
    }
}