.events {
    margin: 0;
    padding: 0;
    list-style: none;
}
.events .ant-badge-status {
    width: 100%;
    overflow: hidden;
    font-size: 12px;
    white-space: nowrap;
    text-overflow: ellipsis;
}
.notes-month {
    font-size: 28px;
    text-align: center;
}
.notes-month section {
    font-size: 28px;
}

.calendar-details-popover {
    margin: 0;
    padding: 0;
}

.calendar-event-item {
    border: solid 1px #ffffff00;
    border-radius: 3px;
}

.calendar-event-item:hover {
    background-color: #dedede;
    border: solid 1px #ffffff00;
    border-radius: 3px;
}


.no-scrollbar {
    width: 100%;
    overflow-y: auto;
    font-family: 'Montserrat', Helvetica, Arial, sans-serif;
    font-weight: normal;
  }
  
  .no-scrollbar::-webkit-scrollbar {
    display: none;
  }
  
  .no-scrollbar {
    -ms-overflow-style: none;
    scrollbar-width: none;
  }
  
  .badge-content .ant-badge-status-text {
    white-space: normal; 
}  

/* Add media query */
@media (max-width: 768px) {
    .ant-picker-calendar.ant-picker-calendar-full .ant-picker-panel .ant-picker-body th {
        width: 120px !important;
    }
    .ant-picker-calendar.ant-picker-calendar-full .ant-picker-panel {
        overflow-x: auto;
    }
}