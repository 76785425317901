.ant-form-item {
    margin: 0 !important;
}

.error-message {
    display: none;
}

.spinner {
    display: flex;
    justify-content: center;
    margin-top: 128px;
}

@media (max-width: 576px) {
    .ant-card-head-title {
        padding: 30px 0 16px !important;
    }
}