.error-message {
    display: none;
}
.participantsInfo{
    display: flex;
    flex-direction: row;
    gap: 0.5em;
    align-items: center;
}

.spinner {
    display: flex;
    justify-content: center;
    margin-top: 128px;
}

@media (max-width: 768px) {
    .search-input-mb {
        width: 100% !important;
        margin-bottom: 10px;
    }
}