@media (max-width: 576px) {
    .text-show {
        display: none !important;
    }

    .ant-list .ant-list-item {
        padding: 12px 0 !important;
    }

    .header-search-button {
        display: grid !important;
    }

    .grid-description {
        display: grid !important;
    }
}

@media (min-width: 576px) {
    .eye-show {
        display: none !important;
    }
}





