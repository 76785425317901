.error-message {
    display: none;
}

.spinner {
    display: flex;
    justify-content: center;
    margin-top: 128px;
}

.linkEntity {
    pointer-events: none;
}



@media (max-width: 568px) {
    .ant-collapse>.ant-collapse-item >.ant-collapse-header {
        flex-wrap: wrap !important;
    }

    .search-input-event{
        width: 15em !important;
    }
    .ant-descriptions .ant-descriptions-header{
        display: flow-root !important;
    }   
}

@media (min-width: 360px) and (max-width: 389px) {
    .search-input-event{
        width: 10em !important;
    }

    .btn-accordion {
        padding: 4px 6px !important;
    }
}

@media (min-width: 389px) and (max-width: 390px) {
    .search-input-event{
        width: 12em !important;
    }
}

@media (max-width: 1024px) {
    .ant-form-horizontal{
        display: grid !important;
    }

    .ant-descriptions .ant-descriptions-item-container{
        display: grid !important;
    }
}