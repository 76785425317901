@media (max-width: 576px) {
    .ant-list-item-meta-content{
        min-width: 100px !important;
    }

    .ant-list .ant-list-item{
        padding: 12px 0 !important;
    }
}

@media (max-width: 768px) {
    .header-search-button {
        display: grid !important;
    }
}



