@media (max-width: 576px) {
    .text-show {
        display: none !important;
    }

    .ant-list .ant-list-item {
        padding: 12px 0 !important;
    }
}

@media (max-width: 600px) {
    .header-search-button {
        display: grid !important;
    }

    .card-content-contact {
        padding: 0 !important;
        margin: 2em 0 !important;
    }
}

@media (min-width: 576px) {
    .eye-show {
        display: none !important;
    }
}



